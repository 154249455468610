
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class SsoUserPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;

  isLoading = false;
  items: any[] = [];
  search = "";
  timeoutId: number | undefined;

  @Watch("search")
  onSearchChange(newSearch: string, oldSearch: string) {
    if (newSearch !== oldSearch && this.isValidEmail(newSearch)) {
      this.searchItems();
    }
  }

  searchItems() {
    clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(async () => {
      this.isLoading = true;
      try {
        if (!this.search) {
          this.items = [];
          return;
        }
        const res = await this.$http.get(
            this.$store.state.ssoUri + "api/search-user?email=" + this.search
        );
        const result = (res.data || res) as any[];
        if (!result || result.length === 0) {
          this.items = [
            {
              id: null,
              name: "Kayıtlı Değil",
              last_name: "",
              mail: this.search,
              incomplete: false
            }
          ];
        } else {
          const user = result[0];
          if (this.isUserIncomplete(user)) {
            user.incomplete = true;
            user.name = "Kayıt tamamlanmamıştır. Lütfen mailinizi kontrol ediniz!";
            user.last_name = user.last_name || "";
            this.items = [user];
          } else {
            this.items = result.map(u => ({
              ...u,
              incomplete: false
            }));
          }
        }
      } catch (e) {
        console.error(e);
        this.items = [];
      } finally {
        this.isLoading = false;
      }
    }, 500);
  }

  userInput() {
    const selectedItem = this.items.find(
        (item) => this.getItemValue(item) === this.localValue
    );
    if (!selectedItem) {
      this.localValue = this.search;
      this.$emit("email", this.search);
      this.$emit("ssouserid", null);
      this.$emit("nonSSOUserSelected", true);
    } else if (selectedItem.id === null && !selectedItem.incomplete) {
      this.localValue = selectedItem.mail;
      this.$emit("email", selectedItem.mail);
      this.$emit("ssouserid", null);
      this.$emit("nonSSOUserSelected", true);
      this.$emit("sendRegistrationMail", true);
    } else if (selectedItem.incomplete) {
      this.localValue = selectedItem.id !== null ? selectedItem.id : selectedItem.mail;
      this.$emit("email", selectedItem.mail);
      this.$emit("ssouserid", selectedItem.id);
      this.$emit("registrationIncomplete", true);
    } else {
      this.localValue = selectedItem.id;
      this.$emit("email", selectedItem.mail);
      this.$emit("ssouserid", selectedItem.id);
      if (selectedItem.confirmed === false) {
        this.$emit("emailNotConfirmed", true);
      } else {
        this.$emit("sendActivationMail", true);
      }
      this.$emit("nonSSOUserSelected", false);
    }
    this.input();
  }

  getItemValue(item: any) {
    return item.id !== null ? item.id : item.mail;
  }

  isValidEmail(email: string): boolean {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  isUserIncomplete(user: any): boolean {
    if (!user) return true;
    const hasName = user.name && user.name.trim();
    const hasLastName = user.last_name && user.last_name.trim();
    return !(hasName && hasLastName);
  }
}
